.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav {
    height: 3.5em;
}
.content {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 3.5em;
}

@media (min-width: 600px) {
    .nav { height: 4em; }
    .content { top: 4em; }
    .main {
        max-width: 800px;
        margin: auto;
    }
}

p {
    margin: 1em;
    text-align: left;
    padding: 0 1em;
}
ul {
    text-align: left;
    padding: 0em 2em
}

li {
    margin: 0 0 1em 1em;
}

.hero-image {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 10em;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../public/hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(2px);
  margin: 0;
}

.hero-logo {
  position: relative;
  top: 0.5em;
  height: 9em
}

.social-logo {
  padding: 0.25em;
  height: 2em;
}

.hero-text {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 10em;
  text-align: center;
  color: white;
  line-height: 10em;
  margin: 0;
}

.main {
    left: 0;
    right: 0;
    top: 11em;
    position: absolute;
}

.isa-image {
  width: 20%;
}

.anchor::before {
  content: "";
  display: block;
  height: 60px; /* fixed header height*/
  margin: -60px 0 0; /* negative fixed header height */
}
